<template>
  <div>
    <div id="preloadingAssets" :class="{active: preloading}">
      <b-progress size="is-small" show-value>{{ $t('system.loadingAssets') }} </b-progress>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      preloading: false,
      assetCount: 0,
      loadedAssetCount: 0,
      assets: [],
      workerController: null,
    };
  },
  methods: {
    LoadedAsset(){
      this.loadedAssetCount++;
      console.log(`Preloaded ${this.loadedAssetCount}/${this.assets.length}`)
      if(this.loadedAssetCount == this.assetCount){
        console.log("🎆 All assets loaded");
        this.preloading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    StartPreloading(assets){
      this.assetCount += assets.length;
      this.assets = [...this.assets, ...assets];
      this.preloading = true;
      assets.forEach((asset, i) => {
        console.log(`Started preloading ${i+1}/${this.assetCount}`)
        fetch(asset)
        // .then(response => response.blob());
          .then(() => { this.LoadedAsset(asset) })
          .catch(error => console.error('*** Error:', error))
      })

      // dejo el mensaje de preloading desactivado 
      // hasta que armemos un sistema bueno de trackear cuantos están cargados
      // this.preloading = true; 
    },
  },
  mounted(){
  }
};
</script>

<style scoped>
#preloadingAssets{
  position: fixed;
  width: 200px;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  transition: all .3s;
  opacity: 0;
}
#preloadingAssets.active{
  opacity: 1;
}
</style>
