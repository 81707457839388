<template>
  <div class="playerAvatarContainer" :class="{ horizontal }">
    <div v-if="$store.state.avatars.length" class="playerAvatar" :style="{
      width: `${scale * 20}px`,
      height: `${scale * 20}px`,
      backgroundColor: `#${player.color}`,
    }">
      <img :src="$store.state.avatars[player.avatar].image.sizes.thumbnail.url" />

    </div>
    <div v-if="showUsername" class="username">{{ player.username }}</div>
  </div>
</template>

<script>
export default {
  props: {
    player: {
      type: Object,
      required: true,
    },
    scale: {
      type: Number,
      required: false,
      default: 1.5,
    },
    showUsername: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.playerAvatarContainer {
  display: flex;
  flex-direction: column;

  &.horizontal {
    flex-direction: row;
    align-items: center;
  }

  .username {
    text-transform: uppercase;
  }
}
</style>
