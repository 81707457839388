var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VideoBackground',{directives:[{name:"show",rawName:"v-show",value:(_vm.standbyMode),expression:"standbyMode"}],attrs:{"playlist":this.options.standByVideos}}),(!_vm.standbyMode)?[(_vm.options && _vm.options.background.video)?_c('VideoDouob',{attrs:{"src":_vm.options.background.video.url,"autoplay":true,"loop":true}}):_vm._e(),(_vm.showTutorial)?_c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-center",staticStyle:{"background-image":"url(/assets/infographic/tutorialscreen.png)"},attrs:{"id":"screenTutorial"}}):_vm._e(),_vm._l((_vm.points),function(point,index){return _c('div',{key:point.id},[(_vm.points.length > 0)?_c('div',[_c('transition',{on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"leave":_vm.leave}},[_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVideoPointPlaying[index]),expression:"isVideoPointPlaying[index]"}],ref:'pointVideo' + index,refInFor:true,staticClass:"primer-plano",attrs:{"id":`video${index}`,"crossorigin":"anonymous"},on:{"ended":function($event){return _vm.CloseEndedVideoPoint(index)}}},[_c('source',{attrs:{"src":_vm.GetVideo(index).url}})])])],1):_vm._e()])}),(!_vm.options.background.video && _vm.options.background.image)?_c('img',{attrs:{"id":"background","src":_vm.options.background.image.url}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTutorial),expression:"!showTutorial"}],attrs:{"id":"points"}},_vm._l((_vm.points),function(point,i){return _c('div',{key:point.id,staticClass:"point",class:{ open: point.open },style:({ '--player-color': point.usersReading.length > 0 ? '#' + point.usersReading[0].color : 'inherit' })},[_c('div',{ref:point.id,refInFor:true,staticClass:"dot",style:({
          top: `${point.posy * _vm.windowHeight}px`,
          left: `${point.posx * _vm.windowWidth}px`,
        })},[_vm._v(_vm._s(i + 1))]),_c('div',{ref:point.id,refInFor:true,staticClass:"outercircle",class:{ outlined: point.usersReading.length == 0 },style:({
          top: `${point.posy * _vm.windowHeight}px`,
          left: `${point.posx * _vm.windowWidth}px`,
        })}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVideoPointPlaying[i]),expression:"isVideoPointPlaying[i]"}],staticClass:"pointTitle",style:({
          top: `${point.posy * _vm.windowHeight}px`,
          left: `${point.posx * _vm.windowWidth}px`,
        })},[_vm._v(" "+_vm._s(point.title)+" ")])])}),0),_c('div',{attrs:{"id":"players"}},_vm._l((_vm.$store.state.space.players),function(player){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ShowPlayer(player)),expression:"ShowPlayer(player)"}],key:player.dbid,staticClass:"player",style:({
          top: `${player.screeny * _vm.windowHeight}px`,
          left: `${player.screenx * _vm.windowWidth}px`,
        })},[_c('div',{staticClass:"point",class:{ clicked: player.clicked },style:({ '--player-color': '#' + player.color })},[_c('svg',{attrs:{"width":"66","height":"67","viewBox":"0 0 66 67","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"33.2993","cy":"33.3999","r":"12.1538","fill":"var(--player-color)"}}),_c('line',{attrs:{"x1":"33.8379","y1":"10.2305","x2":"33.8379","y2":"0.230469","stroke":"var(--player-color)","stroke-width":"5"}}),_c('path',{attrs:{"d":"M33.8379 66.5L33.8379 56.5","stroke":"var(--player-color)","stroke-width":"5"}}),_c('line',{attrs:{"x1":"56","y1":"33.9385","x2":"66","y2":"33.9385","stroke":"var(--player-color)","stroke-width":"5"}}),_c('path',{attrs:{"d":"M0 33.9385L10 33.9385","stroke":"var(--player-color)","stroke-width":"5"}}),_c('circle',{attrs:{"cx":"33.2998","cy":"33.4003","r":"23.3076","stroke":"#FAFAFA","stroke-width":"2"}}),_c('circle',{staticClass:"circleLoader",style:({ 'stroke-dashoffset': player.hoverCountdown }),attrs:{"cx":"33.2998","cy":"33.4003","r":"23.3076","stroke":"var(--player-color)","stroke-width":"5"}})])])])}),0)]:_vm._e(),_c('qr-box',{staticStyle:{"z-index":"10"}}),_c('user-list',{staticStyle:{"z-index":"20"},attrs:{"autohide":false}}),_c('preload-assets',{ref:"PreloadAssets"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }