<template>
  <div class="douob-video-player" v-show="showVideo">
    <video width="216" :autoplay="autoplay" ref="player" crossorigin="anonymous">
      <source :src="src">
      <!-- <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
      Your browser does not support HTML video. -->
    </video>

  </div>
</template>

<script>
export default {
  data() {
    return {
      player: null,
      progress: 0, // normalized
      // src: "http://192.168.0.137:3000/videos/stock-footage-hairy-monster-dancing-hip-hop-northern-soul-floor-combo-fur-bright-funny-fluffy-character-fur.mp4",
      loading: true,
      played: false,
      showVideo: false,
      shouldPlay: false,
    }
  },
  props: {
    src: {
      type: String,
      required: true,
      played: false,
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    pause(){
      this.player.pause()
    },
    play() {
      this.showVideo = true
      this.player.currentTime = 0
      this.player.play()
      this.player.muted = this.muted
    },
    stop() {
      this.showVideo = false
      this.player.pause()
    },
    handleClick() {
      if (this.showVideo && !this.played) {
        // El video deberia haber arrancado pero no habia permisos del navegador
        // Lo arranco manualmente
        this.player.play(); // anda ?
      }
    }
  },
  computed: {
    _muted(){
      if(this.$store.state.space && this.$store.state.space.serverState < 2){
        return true
      }
      return this.muted
    },
    volume() {
      return this.$store.getters["space/config"].space.volume / 100
    }
  },
  watch: {
    volume(val) {
      if (!this.muted) {
        this.player.volume = val
      }
    }
  },
  mounted() {
    this.player = this.$refs.player
    this.player.muted = this.muted
    this.player.addEventListener('ended', () => {
      // console.log("video ended")
      if (this.loop) {
        this.play()
      } else {
        this.showVideo = false;
        this.$emit('ended', this.progress)
      }
    });

    this.player.addEventListener('timeupdate', () => {
      this.progress = this.player.currentTime / this.player.duration
      this.$emit('progress', this.progress)
    });

    this.player.addEventListener('play', () => {
      this.played = true;
      this.showVideo = true;
      window.removeEventListener("click", this.handleClick);
    });

    window.addEventListener("click", this.handleClick);

    // const videoRequest = fetch(this.src)
    //   .then(response => response.blob());
    // videoRequest.then(blob => {
    //   console.log("Finished fetching")
    //   this.loading = false
    //   this.player.src = window.URL.createObjectURL(blob);
    // });

    if (this.autoplay) {
      this.showVideo = true
    }

    if (!this.muted) {
      this.player.volume = this.volume
    }



  },
}
</script>
<style lang="scss" scoped>

.douob-video-player {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>